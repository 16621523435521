.page-encuesta{
    .line-medium{
        position: relative;
        @media (min-width:768px) and (orientation:landscape){
            &:before{
                content:'';
                position: absolute;
                top: 0%;
                left: 50%;
                width: 1px;
                height: 90%;
                background-color:var(--vino);
                opacity: .2;
            }
            .m-3{
                width: 365px !important;
            }
        }
    }
    .page-encuesta{
        .patas{
            &.direction-left{
                left: 10%;
            }
            &.direction-right{
                right: 10%;
            }
        }
        .form-group{
            position: relative;
            select{
                padding-right: 50px;
            }
            .icon-cbo{
                top: 34%;
                right: 6px;
                font-size: 12px;
            }
        }
        .content-pregunta{
            width: 100%;
            position: relative;
            &.is-invalid{
                &::after{
                    content:'Complete el campo';
                    color: var(--vino);
                    font-weight: 600;
                    position: absolute;
                    margin-left: 1rem;
                    padding-left: 20px;
                    bottom: 0%;
                    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
                    background-repeat: no-repeat;
                    background-position: left calc(-.25em + .1875rem) center;
                    background-size: calc(.75em + .375rem) calc(.75em + .375rem);
                    @media (max-width:769px){
                        position: relative;
                        margin-bottom: 5%;
                    }
                }
                .control-label{
                    color: var(--vino);
                }
            }
        }
        .form-control.is-invalid, .was-validated .form-control:invalid {
            background-position: right calc(0.8em + .1875rem) center;
        }
        .form-control.is-valid, .was-validated .form-control:valid {
            background-position: right calc(0.8em + .1875rem) center;
        }
        .msg-recibido{
            border-radius: 50px;
            span{ display: none; }
            &[data-msg=success]{
                border:2px solid #28a745;
                color: #28a745;
                span:nth-child(1){ display: block; }
                span:nth-child(2){ display: none; }
            }
            &[data-msg=danger]{
                border:2px solid var(--vino);
                color: var(--vino);
                span:nth-child(1){ display: none; }
                span:nth-child(2){ display: block; }
            }
        }
        @media (min-width:768px) and (orientation:landscape){
            .lblpregunta{
                margin-bottom: 15px;
            }
            .content-pregunta{
                width: auto;
                height: 300px;
            }
        }
        .section-jumbotron{
            background-color: var(--vino);
            padding-top: 180px;
            padding-bottom: 80px;
            display: flex;
            align-items: center;
            flex-direction: column;
            @media (max-width:400px){
                .content-indicators-time{
                    flex-direction: column;
                }
            }
            .icon-consideracion{
                width: 80px;
                height: 80px;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center center;
            }
            .button-outline{
                margin: 10px;
                border:1px solid rgba(255,255,255,.3);
                padding:10px 20px;
                border-radius: 30px;
                .icon{
                    margin-left: 20px;
                    width: 25px;
                    height: 25px;
                    -webkit-mask-size: contain;
                    -webkit-mask-repeat: no-repeat;
                    -webkit-mask-position: center center;
                }
            }
            .mt-jumbotron{
                margin-top: 3%;
            }
        }
        .section-formulario{
            padding-top: 50px;
            padding-bottom: 50px;
            .content-formulario{
                width: 825px;
                @media (max-width:769px){
                    width: 90%;
                }
            }
            @media (max-width:768px){
                .w-50{
                    width: 90% !important;
                }
            }
        }
    }
    footer{
        margin-bottom: 0px !important;
    }
    .col-lg-45{
      @media (min-width:769px){
          max-width: 48%;
      }  
    }
}
