:root{
    // --amarillo:#f6b700;
    // --vino:#de0031;
    --amarillo:#f6b003;
    --vino:#c42124;
    --marron:#63391e;
    --lightgray:#ededed;
}

.c-amarillo{ color: var(--amarillo) }
.c-vino{ color: var(--vino) }
.c-marron{ color: var(--marron) }
.c-lightgray{ color: var(--lightgray) }

.bg-amarillo{ background-color: var(--amarillo) }
.bg-vino{ background-color: var(--vino) }
.bg-marron{ background-color: var(--marron) }
.bg-lightgray{ background-color: var(--lightgray) }

button.bg-amarillo{ 
    background-color: var(--amarillo);
    &:hover{
        background-color: #e8a602;
    }
}
button.bg-vino{ 
    background-color: var(--vino);
    &:hover{
        background-color: #9c1a1d;
    }
}

.m-auto{ margin: auto; }
.w-90{ width: 90%; }
.w-85{ width: 85%; }
.w-80{ width: 80%; }
.w-70{ width: 70%; }
.w-60{ width: 60%; }
.w-50{ width: 50%; }
.w-40{ width: 40%; }
.w-30{ width: 30%; }
.w-20{ width: 20%; }
.w-10{ width: 10%; }