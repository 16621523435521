.section-promotions{
    padding-top: 2rem;
    .box-image{
        width: 200px;
        height: 225px;
        margin:10px;
        background: #e9ecef;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: move;
        position: relative;
        .closeButton{
            position: absolute;
            top: -10px;
            right: -10px;
            background:black;
            color: #fff;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            justify-content: center;
            align-items: center;
            display: flex;
            opacity: 0;
            cursor: pointer;
            transition: 200ms ease all;
            &:hover{
                color: #dc3545;
            }
        }
        &:hover{
            .closeButton{
                opacity: 1;
                display: flex;
            }
        }
        .progress{
            display: none;
        }
        &.barprogress-true{
            .progress{
                display: flex;
                position: absolute;
                bottom: 0;
                left: 0;
                z-index: 2;
                width: 100%;
                background-color: #fff;
                border:1px solid rgba(0,0,0,.1);
                border-radius: 0px;
            }
        }
    }
}