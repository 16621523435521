.section-loading{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: var(--vino);
    z-index: 9999;
    transition: 600ms ease all;
    opacity: 0;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #b0b2b1;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 600;
    &.active{
        opacity: 1;
        pointer-events: all;
    }
    .o-complete{
        width: 130px;
        height: 130px;
        background-color: var(--amarillo);
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center center;
    }
    .content-loading{
        width: 60px;
        height: 60px;
        position: relative;
        .part{
            width: 100%;
            height: 100%;
            position: absolute;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
            &:nth-child(1){
                -webkit-animation-delay: 100ms;
                animation-delay: 100ms;
            }
            &:nth-child(2){
                -webkit-animation-delay: 300ms;
                animation-delay: 300ms;
            }
            &:nth-child(3){
                -webkit-animation-delay: 500ms;
                animation-delay: 500ms;
            }
            &:nth-child(4){
                -webkit-animation-delay: 800ms;
                animation-delay: 800ms;
            }
        }
    }
    .content-cargando{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        p{
            padding-top: 6%;
            @media (max-width:900px){
                padding-top: 18%;
            }
            span{
                opacity: 0;
                &:nth-child(1){ animation-name: puntoSuspensivo; animation-duration:1s; animation-delay:.2s;animation-iteration-count: infinite;  }
                &:nth-child(2){ animation-name: puntoSuspensivo; animation-duration:1s; animation-delay:.3s;animation-iteration-count: infinite;  }
                &:nth-child(3){ animation-name: puntoSuspensivo; animation-duration:1s; animation-delay:.4s;animation-iteration-count: infinite;  }
            }
        }
    }
}