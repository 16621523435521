header{
    // box-shadow: 0 0 10px rgba(0,0,0,.1);
    // position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    position: absolute;
    z-index: 5;
    // background-color: #fff;
    transition: 500ms ease all;
    background-color: transparent;
    box-shadow: unset;
    @media (max-width:769px){
        background-color: #fff;
        position: fixed;
        box-shadow: 0 0 10px rgba(0,0,0,.1);
    }
    .content-header{
        padding-top: 30px;
        @media (max-width:769px){
            padding-top: 8vw;
            padding-bottom: 8vw;
        }
        .logo{
            // width: 8vw;
            width: calc(8vw + 30px);
            img:nth-child(2){ display: none; }
            @media (max-width:769px){
                width: 28vw;
                img:nth-child(1){ display: none; }
                img:nth-child(2){ display: block; }
            }
            
        }
        .menu{
            margin-left: 6vw;
            display: flex;
            align-items: center;
            justify-content: center;
            li{
                list-style: none;
                margin: 0!important;
                #link-carta{
                    font-size: calc(1vw + 3px);
                    position: relative;
                    font-family: "ProximaNovaRegular";
                    transition: 500ms ease all;
                    display: flex;
                    justify-content: center;
                    color: #fff;
                    position: relative;
                    left: -1.2vw;
                }
            }
            @media (max-width:769px){
                display: none;
            }
            div[data-nav="list"],ul{
                display: flex;
                li{
                    position: relative;
                    display: flex;
                    justify-content: center;
                    margin-left: 1vw !important;
                    margin-right: 1vw !important;
                    &:last-child{
                        margin-right: 40px !important;
                    }
                    a{
                        // font-size: 1vw;
                        font-size: calc(1vw + 3px);
                        position: relative;
                        font-family: "ProximaNovaRegular";
                        transition: 500ms ease all;
                        display: flex;
                        justify-content: center;
                        color: #fff;
                        &.active{
                            color: var(--amarillo);
                            &:before{
                                content:'';
                                 width: 5px;
                                 height: 5px;
                                 border-radius: 50%;
                                 background:var(--amarillo);
                                 position: absolute;
                                 bottom: 0;
                                 transform: translateY(8px);
                             }
                        }
                    }
                }
            }
        }
        .others-option{
            @media (max-width:769px){
                display: none;
            }
            ul li a,ul li button{
                // font-size: .85vw;
                font-size: calc(.85vw + 3px);
                .icon{
                    margin-right: .8em;
                    width: 2.2em;
                    padding-top: 1.7em;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center center;
                    
                }
            }
            
        }
        .menuResponsive{
            display: none;
            align-items: center;
            width: 100%;
            @media (max-width:769px){
                display: flex;
            }
            .button{
                .backdrop{
                    width: 100%;
                    height: 100vh;
                    background: #000;
                    position: fixed;
                    top: 0;
                    left: 0;
                    opacity: 0;
                    transition: opacity .5s ease;
                    pointer-events: none;
                }
                .menu-button{
                    position: relative;
                    z-index: 10;
                    text-indent: -999em;
                    overflow: hidden;
                    margin: auto;
                    display: block;
                    height: 33px;
                    width: 24px;
                    transition: background .8s;
                    background-color: #e5fff6;
                    background: linear-gradient(transparent,transparent 46%,var(--vino) 0,var(--vino) 54%,transparent 0,transparent);
                    border-radius: 10px;
                    &::before,&::after{
                        transition: all .3s ease;
                        position: absolute;
                        content: "a";
                        background-color: var(--vino);
                        height: 3px;
                        width: 24px;
                    }
                    &::before{
                        top: 8px;
                        right: 0;
                    }
                    &::after{
                        bottom: 8px;
                        right: 0;
                    }
                }
                .content-drawer{
                    transform: translateX(-120%);
                    transition: transform .4s ease;
                    z-index: 2;
                    width: 80%;
                    height: 100vh;
                    background: #fff;
                    position: fixed;
                    top: 0;
                    left: 0;
                    padding-bottom: 10px;
                    max-height: 100vh;
                    overflow-y: auto;
                    .menuLinks{
                        height: 95%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        .cd-menu-links-movil{
                            li{
                                margin: 5vw;
                                list-style: none;
                                a{
                                    font-size: 5vw;
                                    &.active{
                                        color:var(--amarillo);
                                    }
                                }
                            }
                        }
                        .cd-menu-social{
                            margin-right: 4vw;
                            ul{
                                li{
                                    margin:0vw 2vw;
                                    font-size: 7vw;
                                }
                            }
                        }
                    }
                }
                &.active{
                    .backdrop{
                        opacity: .5;
                        pointer-events: all;
                    }
                    .menu-button{
                        background: transparent;
                        &::before{
                            transform: rotate(45deg);
                            transform-origin: 50%;
                            top: 50%;
                            background-color: #fff;
                        }
                        &::after{
                            transform: rotate(-45deg);
                            transform-origin: 50%;
                            top: 50%;
                            background-color: #fff;
                        }
                    }
                    .content-drawer{
                        transform: translateX(0);
                    }
                }
            }
        }
    }
    &.fixed{
        background-color: #fff;
        position: fixed;
        box-shadow: 0 0 10px rgba(0,0,0,.1);
        #link-carta{
            color: var(--segundo)!important;
        }
        .content-header{
            padding-top: 20px;
            padding-bottom: 20px;
            @media (max-width:769px){
                padding-top: 8vw;
                padding-bottom: 8vw;
            }
            .logo{
                img:nth-child(1){ display: none; }
                img:nth-child(2){ display: block; }
            }
            .menu{
                div[data-nav="list"],ul{
                    li{
                        a{
                            color: var(--segundo);
                            &.active{
                                color: var(--amarillo);
                            }
                        }
                    }
                }
            }
        }
    }
}