@font-face {
    font-family: "ActoniaHandPersonal";
    font-display: swap;
    src: url("./../fonts/ActoniaHandPERSONAL.woff2") format("woff2");
}

@font-face {
    font-family: "GothamBold";
    font-display: swap;
    src: url("./../fonts/GothamBold.woff2") format("woff2");
}

@font-face {
    font-family: "GothamBook";
    font-display: swap;
    src: url("./../fonts/GothamBook.woff2") format("woff2");
}

@font-face {
    font-family: "GothamLight";
    font-display: swap;
    src: url("./../fonts/GOTHAM-LIGHT.woff2") format("woff2");
}

@font-face {
    font-family: "GothamMedium";
    font-display: swap;
    src: url("./../fonts/GOTHAM-MEDIUM.woff2") format("woff2");
}

@font-face {
    font-family: "ProximaNovaBold";
    font-display: swap;
    src: url("./../fonts/ProximaNovaBold.woff2") format("woff2");
}

@font-face {
    font-family: "ProximaNovaRegular";
    font-display: swap;
    src: url("./../fonts/ProximaNovaRegular.woff2") format("woff2");
}

@font-face {
    font-family: "ProximaNovaThin";
    font-display: swap;
    src: url("./../fonts/ProximaNovaThin.woff2") format("woff2");
}

@font-face {
    font-family: "seguiemj";
    font-display: swap;
    src: url("./../fonts/seguiemj.woff2") format("woff2");
}

.ActoniaHandPersonal{ font-family: "ActoniaHandPersonal"; }
.GothamBold{ font-family: "GothamBold"; }
.GothamBook{ font-family: "GothamBook"; }
.GothamLight{ font-family: "GothamLight"; }
.GothamMedium{ font-family: "GothamMedium"; }
.ProximaNovaBold{ font-family: "ProximaNovaBold"; }
.ProximaNovaRegular{ font-family: "ProximaNovaRegular"; }
.ProximaNovaThin{ font-family: "ProximaNovaThin"; font-weight: 600; }
.seguiemj{ font-family: "seguiemj"; }
