.page-inicio{
    .section-banner{
        width: 100%;
        position: relative;
        @media (max-width:768px){
            margin-top: 25vw;
        }
        .title{
            &:before{
                content: unset;
            }
        }
        .image{
            width: 100%;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: top !important;
            position: relative;
            display: flex;
            justify-content: center;
            height: calc(100vh);
            @media (max-width:768px){
                height: 136vw;
            }
            &:before{
                content:'';
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                position: absolute;
                background: rgba(255,255,255,0);
                background: -moz-radial-gradient(center, ellipse cover, rgba(255,255,255,0) 0%, rgba(0,0,0,0.40) 100%);
                background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba(255,255,255,0)), color-stop(100%, rgba(0,0,0,0.40)));
                background: -webkit-radial-gradient(center, ellipse cover, rgba(255,255,255,0) 0%, rgba(0,0,0,0.40) 100%);
                background: -o-radial-gradient(center, ellipse cover, rgba(255,255,255,0) 0%, rgba(0,0,0,0.40) 100%);
                background: -ms-radial-gradient(center, ellipse cover, rgba(255,255,255,0) 0%, rgba(0,0,0,0.40) 100%);
                background: radial-gradient(ellipse at center, rgba(255,255,255,0) 0%, rgba(0,0,0,0.40) 100%);
            }
            .title{
                position: absolute;
                width: 24vw;
                margin: auto;
                transform: translateY(10vw);
                @media (max-width:768px){
                    width: 90vw;
                }
            }
        }
        .content-social{
            width: 21vw;
            height: 13vw;
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: 2;
            background-color:var(--amarillo);
            -webkit-mask-size: cover;
            -webkit-mask-repeat: no-repeat;
            -webkit-mask-position: center center;
            .content-text{
                position: absolute;
                bottom: 12%;
                right: 10%;
                color: var(--marron);
                span{
                    font-size: 1.4vw;
                    color: var(--marron);
                }
                ul{
                    font-size: 2.1vw;
                    width: 100%;
                    justify-content: space-between;
                    li{
                        margin-left: .7vw;
                        margin-right: .7vw;
                        a{
                            color: var(--marron);
                            i{
                                color: var(--marron);
                            }
                        }
                    }
                }
            }
        }
    }
    .section-promociones{
        width: 100%;
        // background-color: var(--vino);
        background: #fff;
        position: relative;
        z-index: 4;
        padding-top: 4%;
        padding-bottom: 1%;
        .promotions-extended{
            .content-img-promotion{
                margin-top: 2em;
                img{
                    border:8px solid #fff;
                }
            }
            button{
                transform: translateY(2vw);
            }
            .btn-reverse{
                button{
                    i{
                        transform: rotate(180deg);
                    }
                }
            }
        }
        .w-90{
            width: 90%;
            @media (max-width:768px){
                width: 100%;
            }
        }
        .w-80{
            width: 80%;
            @media (max-width:768px){
                width: 100%;
            }
        }
        .patas{
            &.direction-left{
                left: 3%;
                // top: -15%;
            }
            &.direction-right{
                right: 3%;
                // top: 32%;
            }
        }
        .owl-nav{
            .owl-prev,.owl-next{
                position: absolute;
                color: #fff;
                font-size: 30px;
                background-color: #fff;
                width: 58px;
                height: 58px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                filter: drop-shadow(0px 0px 10px rgba(0,0,0,.3));
                i{
                    color: var(--marron);
                }
            }
            .owl-prev{
                left: 3%;
                bottom: 5.5vw;
            }
            .owl-next{
                right: 2%;
                top: 4vw;
            }
            @media (max-width:769px){
                .owl-prev{ left: 9%; bottom: 9.5vw; }
                .owl-next{ right: 9%; }
            }
        }
        .owl-dots{
            display: flex;
            margin: auto;
            width: max-content;
            transform: translateY(15px);
            .owl-dot{
                width: 15px;
                height: 15px;
                margin:5px;
                border-radius: 50%;
                border:1px solid var(--vino);
                background:transparent;
                transition: 500ms ease background;
                &.active{
                    background: var(--vino);
                }
                @media (max-width:768px){
                    border:1px solid var(--marron);
                    &.active{
                        background: var(--marron);
                    }
                }
            }
        }
        .owl-stage{
            .owl-item{
                transform: scale(.8);
                transition: 500ms ease all;
                @media (max-width:768px){
                    transform: scale(1);
                }
                &.center{
                    transform: scale(1);
                }
            }
        }
        .content-image{
            transform: rotate(-2deg);
            padding: 10px;
            @media (max-width:768px){
                width: auto !important;
            }
            .image{
                width: 100%;
                padding-top: 114%;
                @media (max-width:768px){
                    width: 70vw !important;
                    padding-top: 113%;
                }
            }
        }
    }
    .section-ubicacion{
        @media (max-width:768px){
            .w-70{ width: 100%; }
            .w-sm-80{ width: 80%; }
            .w-80{ width: 100%; }
            .w-50{ width: 100% !important; }
            .content-ubicacion{
                flex-direction: column;
            }
            .map{
                height: 110vw;
            }
        }
        .o-partido{
            background-color: #a51c1f;
            -webkit-mask-size: contain;
            -webkit-mask-repeat: no-repeat;
            -webkit-mask-position: center center;
            position: absolute;
            left: 0;
            top: 17%;
            width: 15%;
            padding-top: 25%;
            @media (max-width:769px){
                top: 22%;
                width: 34%;
                padding-top: 55%;
            }
        }
        .patas{
            background-color: #a51c1f;
            &.direction-right{
                top: unset;
                right: 3%;
                bottom: 0%;
            }
            @media (max-width:769px){
                display: none;
            }
        }
        .content-false-box-select{
            .contentOptionsSelect{
                background-color: #fff;
                display: none;
                position: absolute;
                width: 100%;
                z-index: 2;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
                .options{
                    padding:10px;
                    border-bottom: 1px solid rgba(0,0,0,.05);
                }
            }
            &.active{
                .contentOptionsSelect{
                    display: block;
                }
            }
        }
        // .promotions-extended{
        //     .content-img-promotion{
        //         margin-top: 2em;
        //         img{
        //             border:8px solid #fff;
        //         }
        //     }
        //     button{
        //         transform: translateY(2vw);
        //     }
        //     .btn-reverse{
        //         button{
        //             i{
        //                 transform: rotate(180deg);
        //             }
        //         }
        //     }
        // }
        .selector-ubicacion{
            padding-top: 12.5vw;
            padding-bottom: 2vw;
            @media (max-width:769px){
                .text-direccion{ font-size: 4vw; }
                .text-phone{ font-size: 4vw; }
            }
        }
        // .map{
        //     div:nth-child(1){
        //         width: 100%;
        //         height: 125vw;
        //     }
        // }
        .attr-ubicacion{
            margin: 2%;
            margin-bottom: 5%;
            border-radius: 20px;
            padding-top: 5%;
            padding-bottom: 5%;
            padding-left: 8%;
            padding-right: 4%;
            // width: 20vw;
            background: transparent;
            transition: 500ms ease-out background;
            cursor: pointer;
            span,i,div{
                color: #fff;
            }
            &.active, &:hover{
                background: var(--amarillo);
                span,i,div{
                    color: var(--marron);
                }
            }
            .marker-icon{
                font-size: 1vw;
                transform: translateY(.2vw);
            }
            .ProximaNovaBold{
                font-size: calc(1vw + 3px);
            }
            .ProximaNovaThin{
                font-size: calc(.8vw + 2px);
                // font-weight: 600;
            }
            .GothamMedium{
                font-size: calc(.75vw + 3px);
            }
        }
    }
    .section-contacto{
        padding-top: 4vw;
        padding-bottom: 4vw;
        position: relative;
        @media (max-width:769px){
            .patas{
                display: none;
            }
        }
        .o-partido{
            background-color: #f8f8f7;
            -webkit-mask-size: contain;
            -webkit-mask-repeat: no-repeat;
            -webkit-mask-position: center center;
            position: absolute;
            left: 0;
            bottom: 0%;
            width: 10%;
            padding-top: 15%;
            @media (max-width:769px){
                bottom: 12%;
                width: 30%;
                padding-top: 50%;
            }
        }
        .input-group{
            @media (max-width:769px){
                .form-control{
                    display: flex;
                    align-items: center;
                    padding-top: 0px;
                    padding-bottom: 0px;
                }
            }
            .input-group-append{
                position: absolute;
                right: 5%;
                height: 100%;
                .input-group-text{
                    border: 0px;
                    background:transparent;
                    padding-right: 0px;
                    // padding: .375rem 1.5rem;
                }
            }
            &:hover{
                .input-group-append{
                    display: none;
                }
            }
            .input-group-icon{
                width: 20px;
                height: 20px;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center center;
            }
            .form-control{
                border-radius: 2em;
            }
        }

        .form-group{
            @media (max-width:769px){
                button{ font-size: 1.5rem; }
            }
            .control-label{
                padding-left: 23px;
            }
            .form-control{
                font-size: 1em;
                height: 3em;
                padding-left: 30px;
            }
        }
    }
    @media (max-width:768px){
        .btn-view-promos{
            margin-top: 20px !important;
            margin-bottom: 20px !important;
            font-size: 1.5rem;
        }
        .content-false-box-select{
            width: 80%;
            margin: auto;
            position: relative;
            .select-box-false{
                padding: 10px 18px;
                background-color: var(--amarillo);
                border-radius: 8px;
                font-size: 18px;
            }
        }
    }
    @media (max-width:500px){
        .btn-view-promos{
            margin-top: 20px !important;
            margin-bottom: 20px !important;
            font-size: 1rem;
        }
        .section-contacto{
            .form-group{
                .icon-cbo{
                    display: none;
                }
                button{
                    font-size: 1rem;
                }
            }
        }
    }
}