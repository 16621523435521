*{
  margin:0;
  padding:0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  outline: none;
  ul{
      margin-block-start: unset;
      margin-block-end: unset;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding-inline-start: unset;
      list-style: none;
  }
  -webkit-tap-highlight-color: transparent;
  -moz-osx-tap-highlight-color: transparent;
  button{
      outline: none;
  }
  .btn-light{
    border-color: transparent;
  }
  #lg-share{ display: none !important; }
  #lg-actual-size{ display: none !important; }
  #lg-download{ display: none !important; }
  .lg-autoplay-button{ display: none !important; }
  .lg-backdrop{ opacity:0.9 !important; }
  .lg-toolbar { background-color: transparent !important; }

  .v-hidden{ visibility: hidden; }
  .input-group{
    .form-control{
      background-image: unset !important;
    }
  }
  .btn:focus{
    box-shadow: unset;
  }
  .btn-rounded{
    border-radius: 60px;
  }
  a:link{
    text-decoration: none;
  }
  a{
    color: #565656;
  }
  .section{
    position: relative;
  }
  .p-relative{
    position: relative;
  }
}
select, option, input[type=date], input[type=time] {
  -webkit-appearance: none;
}
.page-home, .page-encuesta{
  .form-group{
    .icon-cbo{
      position: absolute;
      right: 3rem;
      top: 50%;
    }
  }
  html,body{
    font-family: "ProximaNovaRegular";
    color: #565656;
    height: 100vh;
    position: relative;
  }
  html{
    &.blocked{
      overflow: hidden;
    }
  }
  .dropdown{
    .dropdown-toggle{
      &::after{
        content:'';
        background-color: var(--marron);
        -webkit-mask-image:url("Assets/images/arrow-down.svg");
        -webkit-mask-size: contain;
        -webkit-mask-repeat: no-repeat;
        -webkit-mask-position: center center;
        position: absolute;
        right: 10%;
        width: 15px;
        height: 15px;
        margin-left: 0px;
        vertical-align: 0px;
        content: "";
        border-top: 0px solid;
        border-right: 0px solid transparent;
        border-bottom: 0;
        border-left: 0px solid transparent;
        transition: 500ms ease transform;
      }
      // &:focus,&:active{
      //   border-bottom-left-radius: 0px;
      //   border-bottom-right-radius: 0px;
      //   border-top-right-radius: 20px;
      //   border-top-left-radius: 20px;
      //   &::after{
      //     transform: rotate(180deg);
      //   }
      //   &+.dropdown-menu{
      //     display: block;
      //     border-top-left-radius: 0px;
      //     border-top-right-radius: 0px;
      //   }
      // }
    }
    .dropdown-menu{
      width: 100%;
      padding: 0px;
      margin: 0px;
      border:0px;
      border-radius: 1rem;
      background: var(--amarillo);
      a{
        font-size: 16px !important;
        color: var(--marron);
        &.dropdown-item{
          &:active{
            // background-color: #d49804;
            background-color: transparent;
          }
          &:hover{
            // background-color:#d49804 !important;
            background-color: transparent !important;
          }
        }
        &:last-child{
          &:hover{
            border-bottom-left-radius: 1rem;
            border-bottom-right-radius: 1rem;
          }
        }
        i{
          font-size: 12px;
        }
        .ProximaNovaThin{
          font-size: 14px;
        }
      }
    }
    &.active{
      .dropdown-toggle{
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
      }
      .dropdown-menu{
        display: block;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        a{
          &:nth-child(1){
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
          }
        }
      }
    }
    &.show{
      .dropdown-toggle{
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
        border-top-right-radius: 20px !important;
        border-top-left-radius: 20px !important;
        &::after{
          transform: rotate(180deg);
        }
        &+.dropdown-menu{
          display: block;
          border-top-left-radius: 0px !important;
          border-top-right-radius: 0px !important;
        }
      }
    }
  }
  .navigation-bottom-fixed{
    display: none;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-top: 7px;
    padding-bottom: 7px;
    background-color: #fff;
    z-index: 4;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    @media (max-width:769px){
      display: flex;
    }
    .content-icon {
      font-size: 3vw;
      .icon{
        margin-left: 8vw;
        margin-right: 8vw;
        width: 8vw;
        height: 6vw;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
  }
  .pollo{
    width: 20%;
    right: 20%;
    bottom: 35%;
    padding-top: 26%;
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    z-index: -1;
  }
  .patas{
    width: 7vw;
    padding-top: 14vw;
    background: #f0f0ef;
    -webkit-mask-size: contain;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: center center;
    position: absolute;
    top: 0;
    transition: 1000ms ease all;
    &.direction-left{
        left: 3%;
        // top: -15%;
    }
    &.direction-right{
        right: 3%;
        // top: 32%;
    }
  }
  .form-control{
    border-radius: 3em;
    border-color: #c6c6c6;
    &::placeholder{
      color: #c6c6c6;
    }
  }
  .lineheight-1{ line-height: 1em; }
  .owl-carousel{
    width: 100%;
    .owl-stage-outer{
        width: 100%;
        height: 100%;
        .owl-stage{
            width: 100%;
            height: 100%;
            .owl-item{
                width: 100%;
                height: 100%;
                .content-image{
                    width: 100%;
                    height: 100%;
                    .image{
                        width: 100%;
                        background-size: cover;
                        background-repeat: no-repeat;
                        background-position: center center;
                        position: relative;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }
    }
  }
  .curv1{
    width: 100%;
    height: 12.2vw;
    background-color: #fff;
    -webkit-mask-size: cover;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: center center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    button{
        height: max-content;
        font-size: 1.1vw;
        border-color: var(--amarillo);
    }
  }
  .title{
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    width: max-content;
    margin: auto;
    margin-bottom: 3.5vw;
    // filter: drop-shadow(0px 0px 13px rgba(0,0,0,1));
    @media (max-width:768px){
      margin-top: 5vw;
      margin-bottom: 10vw;
    }
    span{
        // color: #fff;
        display: flex;
        flex-direction: column;
        line-height: 1.1em;
        &:nth-child(1){
          font-family: "ProximaNovaRegular";
          font-size: 1.4vw;
        }
        &:nth-child(2){
          font-family: "ActoniaHandPersonal";
          font-size: 3.7vw;
        }
        @media (max-width:768px){
          &:nth-child(1){
            font-size: 6.4vw;
          }
          &:nth-child(2){
            font-size: 11.3vw;
          }
        }
    }
    &:before{
        content: '';
        position: absolute;
        width: 40%;
        padding-top: 10%;
        bottom: -30%;
        left: 30%;
        background-color: var(--amarillo);
        -webkit-mask-image: url("Assets/images/promociones/linea-ondulada.svg");
        -webkit-mask-size: contain;
        -webkit-mask-repeat: no-repeat;
        -webkit-mask-position: center center;
        z-index: 2;
    }
  }
  .input-group-append{
    pointer-events: none;
  }
  .mobile-deliverys{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
    transform: translateY(100%);
    transition: 500ms ease transform;
    &.active{
      transform: translateY(0%);
    }
    a:hover{
      color: var(--marron);
    }
    .btn-phone{
      box-shadow: 1px 2px 2px rgba(0,0,0,.13);
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
    }
  }
}

@import "Assets/scss/loading.scss";
@import "Assets/scss/animate.scss";
@import "Assets/scss/fonts.scss";
@import "Assets/scss/inicio.scss";
@import "Assets/scss/encuesta.scss";
@import "Assets/scss/header.scss";
@import "Assets/scss/footer.scss";
@import "Assets/scss/common/index.scss";
