.page-admin{    
    // background-color: #e9ecef;
    .form-group{
        margin-bottom: 0px;
    }
    .headerfobic {
        margin-top: 6rem !important;
    }
    padding-top: 4.4rem;
}
